<template>
  <div>
    <Modal
      v-model="createModal"
      width="600"
    >
      <p
        slot="header"
        class="text-center"
      >创建施工申请</p>
      <div>
        <div class="m-b-10">
          <div class="m-b-10">选择创建类型</div>
          <RadioGroup v-model="taskModel.type">
            <Radio
              v-for="item in typeArray"
              :key="'ty_'+item.value"
              :label="item.value"
            >{{ item.name }}</Radio>
          </RadioGroup>
        </div>
        <div>
          <div class="m-b-10">选择作业计划日期</div>
          <div>
            <DatePicker
              :options="dateOptions"
              transfer
              type="daterange"
              size="small"
              placeholder="选择起止日期"
              style="width: 100%"
              @on-change="onChangeDateRange"
            ></DatePicker>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="createModal = false"
        >取消</Button>
        <Button
          type="primary"
          :disabled="btnSubmit"
          @click="onConfirm"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createWorkdayTable } from '@/api/msp/workdayTable'
export default {
  props: {
    typeArray: {
      type: Array,
      default: new Array(0)
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      createModal: false,
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      taskModel: {
        type: 1,
        startDate: '',
        endDate: ''
      }
    }
  },
  computed: {
    btnSubmit () {
      return this.taskModel.startDate === '' || this.taskModel.endDate === ''
    }
  },
  methods: {
    showModal () {
      this.createModal = true
    },
    onChangeDateRange (dates) {
      this.taskModel.startDate = dates[0]
      this.taskModel.endDate = dates[1]
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: async () => {
          const result = await createWorkdayTable(this.taskModel)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.createModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>

<style>
</style>
